import { Component, Inject, Vue, Watch } from "vue-property-decorator";
import { CONTAINER_TYPES } from "../infrastructure/ioc/container-types";
import ServiceInterface from "../service/service-interface";
import Utils from "@/vue/infrastructure/utils/helpers";
import "tailwindcss/tailwind.css";
import "vue-multiselect/dist/vue-multiselect.min.css";
import DfStore from "@/vue/domain/store/df-store";
import DfBrand from "@/vue/domain/brand/df-brand";
import DfPromotion from "@/vue/domain/promotion/df-promotion";
import DfProduct from "@/vue/domain/product/df-product";
import DfCategory from "@/vue/domain/category/df-category";
import DfPaging from "@/vue/domain/paging/df-paging";
import DfShoppingProduct from "@/vue/domain/shopping-product/df-shopping-product";
import DfGroup from "@/vue/domain/group/df-group";
import DfContent from "@/vue/domain/content/df-content";
import Firebase from "@/vue/infrastructure/utils/firebase";
import Swal from "sweetalert2";
import DfCoordinates from "@/vue/domain/store/df-coordinates";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
const DfSetComponent = () => import(/* webpackChunkName: 'df-card-product' */ "@/vue/components/df-set/df-set.vue");
const DfModalComponent = () => import(/* webpackChunkName: 'df-modal' */ "@/vue/components/df-modal/df-modal.vue");
const lazyLoadingEnabled = process.env.VUE_APP_LAZY_LOADING_ENABLED;

@Component({
  components: {
    DfHeader: () => Utils.externalComponent2("df-header"),
    DfFooter: () => Utils.externalComponent2("df-footer"),
    DfAppNavigation: () => Utils.externalComponent2("df-app-navigation"),
    DfModal: DfModalComponent,
    DfSet: DfSetComponent,
    DfSectionProductsFilter: () => Utils.externalComponent2("df-section-products-filter"),
    PulseLoader,
    DfProductDetailsData: () => Utils.externalComponent2("df-product-datails-data"),
    DfProductDetailsRelatedProducts: () => Utils.externalComponent2("df-product-datails-related-products"),
    DfProductDetailsExtraContent: () => Utils.externalComponent2("df-product-datails-extra-content"),
    DfProductDetailsImages: () => Utils.externalComponent2("df-product-details-images"),
  },
})
export default class ApplicationComponent extends Vue {
  @Inject(CONTAINER_TYPES.ServiceInterface)
  private service!: ServiceInterface;

  private storeLoaded = false;
  private storesLoaded = false;
  private brandLoaded = false;
  private busy = false;
  private brandContents: Array<string> = ["BAN", "BTN", "NWS", "PSH", "CMP", "RCP", "PAG", "FML"];
  private storeContents: Array<string> = ["BTN", "NWS", "PSH", "CMP", "RCP", "FLY"];
  private promotionContentsCodes: Array<string> = ["BAN", "FEA", "SPC", "TAG", "CAT", "FLY", "RCP", "POP"];
  private geolocation: DfCoordinates = null;
  private lazyLoadingEnabled = true;
  private modalProduct: DfProduct = null;
  private searchPdfViewerInterval: any = null;

  get readyToStart(): boolean {
    const rule01: boolean = this.storeLoaded && this.brandLoaded && !!this.currentStore;
    const rule02: boolean = this.storeLoaded && this.brandLoaded && this.storesLoaded;

    return rule01 || rule02;
  }

  get loadingBackgroundStyle(): string {
    return `background-color: ${Utils.getLoadingBackgroundColor()}`;
  }

  get developmentMode(): boolean {
    return Utils.getProfileMode() === Utils.PROFILE_MODE_DEVELOPMENT;
  }

  get developmentModeLogo(): string {
    return `${Utils.getPublicPath()}/assets/develop-mode-logo.gif`;
  }

  get currentStore(): DfStore {
    return this.$store.getters.currentStore;
  }

  set currentStore(currentStore: DfStore) {
    this.$store.dispatch("setCurrentStore", currentStore);
  }

  get stores(): Array<DfStore> {
    return this.$store.getters.stores;
  }

  set stores(stores: Array<DfStore>) {
    this.$store.dispatch("setStores", stores);
  }

  get currentPromotion(): DfPromotion {
    return this.$store.getters.currentPromotion;
  }

  set currentPromotion(currentPromotion: DfPromotion) {
    this.$store.dispatch("setCurrentPromotion", currentPromotion);
  }

  set promotions(promotions: Array<DfPromotion>) {
    this.$store.dispatch("setPromotions", promotions);
  }

  get shoppingList(): Array<DfShoppingProduct> {
    return this.$store.getters.shoppingList;
  }

  set shoppingList(shoppingList: Array<DfShoppingProduct>) {
    this.$store.dispatch("setShoppingList", shoppingList);
  }

  get storedPushesList(): Array<any> {
    return this.$store.getters.storedPushesList;
  }

  set storedPushesList(storedPushesList: Array<any>) {
    this.$store.dispatch("setStoredPushesList", storedPushesList);
  }

  get flyerSwitchActive(): boolean {
    return this.$store.getters.flyerSwitchActive;
  }

  get showAppNavigation(): boolean {
    return this.$route.name !== "store-selection";
  }

  get showFilterMenu(): boolean {
    return this.$route.name === "promotion" && !this.flyerSwitchActive;
  }

  get products(): Array<DfProduct> {
    return this.$store.getters.products;
  }

  set products(products: Array<DfProduct>) {
    this.$store.dispatch("setProducts", products);
  }

  get cachedProducts(): Array<DfProduct> {
    return this.$store.getters.cachedProducts;
  }

  set cachedProducts(products: Array<DfProduct>) {
    this.$store.dispatch("setCachedProducts", products);
  }

  get productsPaging(): DfPaging {
    return this.$store.getters.productsPaging;
  }

  set productsPaging(productsPaging: DfPaging) {
    this.$store.dispatch("setProductsPaging", productsPaging);
  }

  get storesPaging(): DfPaging {
    return this.$store.getters.storesPaging;
  }

  set storesPaging(storesPaging: DfPaging) {
    this.$store.dispatch("setStoresPaging", storesPaging);
  }

  get lastProductsPage(): boolean {
    return this.productsPaging ? this.productsPaging.last : true;
  }

  get promotionContents(): Array<DfContent> {
    return this.$store.getters.promotionContents;
  }

  get tags(): Array<DfCategory> {
    const tagsContent: Array<DfContent> = this.promotionContents.filter((content: DfContent) => content.type.code === "TAG");
    const primaryTag: Array<DfCategory> = tagsContent
      .filter((tagContent: DfContent) => Utils.getPropertyValue(tagContent, Utils.PROPERTY_CONTENT_PRIMARY, "BOOLEAN"))
      .sort((contentA: DfContent, contentB: DfContent) => contentB.priority - contentA.priority)
      .map((tagContent: DfContent) => new DfCategory(tagContent.alias, tagContent.name));
    const secondaryTag: Array<DfCategory> = tagsContent
      .filter((tagContent: DfContent) => !Utils.getPropertyValue(tagContent, Utils.PROPERTY_CONTENT_PRIMARY, "BOOLEAN"))
      .sort((contentA: DfContent, contentB: DfContent) => contentB.priority - contentA.priority)
      .map((tagContent: DfContent) => new DfCategory(tagContent.alias, tagContent.name));

    return [...primaryTag, ...secondaryTag];
  }

  get selectedTags(): Array<DfCategory> {
    return this.$store.getters.activeTags;
  }

  get selectedCategory(): any {
    const category: DfContent = this.$store.getters.activeCategory;
    return category ? { code: category.alias, label: category.name } : null;
  }

  get productsQueryValue(): string {
    return this.$store.getters.productsQueryValue;
  }

  get clientNotification(): boolean {
    return this.$store.getters.clientNotification;
  }

  set clientNotification(clientNotification: boolean) {
    if (clientNotification) {
      this.askNotificationPermission();
    } else {
      this.$store.dispatch("setClientNotification", clientNotification);
      Utils.localStorageSetItem(Utils.LOCAL_STORAGE_CLIENT_NOTIFICATION, clientNotification.toString());
    }
  }

  get clientNotificationSupported(): boolean {
    return "Notification" in window;
  }

  get geolocationEnabled(): boolean {
    return this.$store.getters.geolocationEnabled;
  }

  set geolocationEnabled(geolocationEnabled: boolean) {
    this.$store.dispatch("setGeolocationEnabled", geolocationEnabled);
  }

  get customFiltersQuery(): Array<string> {
    return this.$store.getters.customFiltersQuery;
  }

  get promotionsLoaded(): boolean {
    return this.$store.getters.promotionsLoaded;
  }

  set promotionsLoaded(promotionsLoaded: boolean) {
    this.$store.dispatch("setPromotionsLoaded", promotionsLoaded);
  }

  get brand(): DfBrand {
    return this.$store.getters.brand;
  }

  set brand(brand: DfBrand) {
    this.$store.dispatch("setBrand", brand);
  }

  get storeDefaultViewMode(): any {
    const valueList: any = Utils.getPropertyValue(this.currentStore, Utils.PROPERTY_PRODUCTS_VIEW_MODE, "LIST", { key: Utils.PRODUCTS_ALL_VIEWS });
    return valueList.key;
  }

  beforeMount() {
    localStorage.removeItem("pdfjs.history");
    //listen for link click events at the document level
    if (document.addEventListener) {
      document.addEventListener("click", this.interceptClickEvent);
    }

    const shoppingList: string = Utils.localStorageGetItem("df-shopping-list");
    if (shoppingList) {
      this.shoppingList = JSON.parse(shoppingList).map((data: any) => {
        return new DfShoppingProduct(data._description, data._checked);
      });
    }
    this.registerServiceWorker();
    this.$store.dispatch("setClientNotification", Utils.localStorageGetItem(Utils.LOCAL_STORAGE_CLIENT_NOTIFICATION) === "true");
    this.storedPushesList = Utils.localStorageGetItem("df-pushes-list") ? JSON.parse(Utils.localStorageGetItem("df-pushes-list")) : [];
    this.lazyLoadingEnabled = lazyLoadingEnabled === "true";
    this.login();
  }

  mounted() {
    document.documentElement.style.setProperty("--app-height", `${window.innerHeight}px`);
  }

  created() {
    this.$root.$on("loadProductsPage", (pagingData: DfPaging) => {
      this.loadProducts(pagingData);
    });
    this.$root.$on("loadStores", (pagingData: DfPaging, searchQuery: string) => {
      this.loadStores(pagingData, searchQuery);
    });
    this.$root.$on("loadCachedProducts", (productsUniqueId: Array<string>) => {
      this.loadCachedProducts(productsUniqueId);
    });
    this.$root.$on("loadCachedProduct", (productsAlias: string, callback: any) => {
      this.loadCachedProduct(productsAlias, callback);
    });
    this.$root.$on("loadNextProductsPage", () => {
      if (this.lazyLoadingEnabled) {
        this.loadNextProductsPage();
      }
    });
    this.$root.$on("loadNextProductsPageManual", () => {
      this.loadNextProductsPage();
    });
    this.$root.$on("login", () => {
      this.login();
    });

    this.initFirebase();
    document.addEventListener("onFirebaseReady", this.initFirebase, false);
    if ((<any>window).digitalFlyerMessage) {
      this.handleNotificationMessage((<any>window).digitalFlyerMessage);
    }

    window.addEventListener("message", this.smtPdfViewerEventHandler, false);
  }

  @Watch("readyToStart")
  private readyToStartChanged() {
    if (this.readyToStart) {
      //Firebase.subscribe("WWNDQYSSCZNME");
      if ((<any>navigator).splashscreen) {
        (<any>navigator).splashscreen.hide();
      }
      if (this.$route.name !== "page") {
        if (!this.currentStore && this.$route.name !== "store-selection") {
          this.$store.dispatch("setInitialRoute", this.$route);
          this.$router.push({ name: "store-selection" });
        } else if (this.currentStore && (this.$route.name === "store-selection" || !this.$route.name)) {
          this.$router.push({ name: "home-store", params: { storeAlias: this.currentStore.alias } });
        } else if (this.currentStore && (this.$route.meta || {}).redirectTo) {
          this.$router.replace({
            name: this.$route.meta.redirectTo,
            params: Object.assign({}, this.$route.params, { storeAlias: this.currentStore.alias }),
            query: this.$route.query,
          });
        }
      }
    }
  }

  @Watch("currentStore")
  private currentStoreChanged(newStore: DfStore, oldStore: DfStore) {
    const rule01 = !!newStore;
    const rule02: boolean = newStore && oldStore ? newStore.alias !== oldStore.alias : true;
    if (rule01 && rule02) {
      this.promotions = [];

      this.loadStoreContents(this.storeContents);
      this.loadStoreOpenings();
      this.loadStoreClosures();
      this.loadPromotions();
    }
  }

  @Watch("currentPromotion", { immediate: true })
  private currentPromotionChanged(newPromotion: DfPromotion, oldPromotion: DfPromotion) {
    const rule01 = !!newPromotion;
    // const rule02: boolean = newPromotion && oldPromotion ? newPromotion.alias !== oldPromotion.alias : true;
    if (rule01) {
      this.loadPromotionContents(this.promotionContentsCodes);
      this.loadPromotionGroups();
      this.$store.dispatch("setActiveCategory", null);
      this.$store.dispatch("setCachedProducts", []);
    }
  }

  @Watch("shoppingList")
  private shoppingListChanged() {
    this.saveShoppingList();
  }

  @Watch("$route", { immediate: true })
  private routeChanged() {
    if (this.$route.params.promotionAlias) {
      this.currentPromotion = this.$store.getters.allPromotions.find((promotion: DfPromotion) => promotion.alias === this.$route.params.promotionAlias) || null;
    } else if (this.$route.name === "promotions" && this.$route.query.tags && this.$store.getters.allPromotions.length) {
      this.currentPromotion = this.$store.getters.allPromotions[0];
    } else {
      this.currentPromotion = null;
    }

    const currentStoreAlias: string = this.$route.params.storeAlias || Utils.localStorageGetItem("df-current-store-alias");
    // Nota: può accadere che il current store non sia presente nella lista degli store a causa della paginazione
    const allStores: Array<DfStore> = this.currentStore ? [...this.stores, this.currentStore] : this.stores;
    this.currentStore = currentStoreAlias ? allStores.find((store: DfStore) => store.alias === currentStoreAlias) || null : null;
    Utils.localStorageSetItem("df-current-store-alias", currentStoreAlias || "");

    if (this.currentStore) {
      if ((this.$route.meta || {}).redirectTo) {
        this.$router.replace({
          name: this.$route.meta.redirectTo,
          params: Object.assign({}, this.$route.params, { storeAlias: this.currentStore.alias }),
          query: this.$route.query,
        });
      } else if (this.$route.name === "promotions" && this.currentPromotion) {
        this.$router.replace({
          name: "promotion",
          params: Object.assign({}, this.$route.params, { storeAlias: this.currentStore.alias, promotionAlias: this.currentPromotion.alias }),
          query: this.$route.query,
        });
      }
    }
  }

  @Watch("geolocationEnabled")
  private geolocationEnabledChange() {
    this.getCurrentPosition();
  }

  @Watch("promotionsLoaded")
  private promotionsLoadedChange() {
    if (this.promotionsLoaded && this.$route.name === "promotions" && this.$route.query.tags) {
      this.currentPromotion = this.$store.getters.allPromotions[0];
      this.$router.replace({
        name: "promotion",
        params: Object.assign({}, this.$route.params, { storeAlias: this.currentStore.alias, promotionAlias: this.currentPromotion.alias }),
        query: this.$route.query,
      });
    }
  }

  private login() {
    this.service
      .login()
      .then(() => {
        this.geolocationEnabled = Utils.localStorageGetItem(Utils.LOCAL_STORAGE_GEO_LOCALIZATION) === "true";
        this.loadStore(Utils.localStorageGetItem("df-current-store-alias"));
        this.loadBrand();
        this.loadBrandContents(this.brandContents);
      })
      .catch((error: Error) => {
        Utils.defaultApiErrorHandler(error, this);
      });
  }

  private loadBrand() {
    this.service
      .getBrand()
      .then((brandData: DfBrand) => {
        this.brand = brandData;
        this.brandLoaded = true;

        const brandName: string = Utils.getPropertyValue(this.brand, Utils.PROPERTY_BRAND_NAME, "STRING", this.brand.description);
        document.title = `${document.title} | ${brandName}`;
      })
      .catch((error: Error) => {
        Utils.defaultApiErrorHandler(error, this);
      });
  }

  private loadBrandContents(typeCode: Array<string>) {
    this.service
      .getBrandContents({ typeCode: typeCode })
      .then((response: any) => {
        this.$store.dispatch("setBrandContents", response);
      })
      .catch((error: Error) => {
        Utils.defaultApiErrorHandler(error, this);
      });
  }

  private loadStore(storeAlias: string) {
    if (storeAlias) {
      this.service
        .getStore({ storeAlias: storeAlias })
        .then((store: DfStore) => {
          this.currentStore = store;
          this.storeLoaded = true;
        })
        .catch((error: Error) => {
          this.currentStore = null;
          this.storeLoaded = true;
          this.$router.push({ name: "store-selection" });
        });
    } else {
      this.storeLoaded = true;
    }
  }

  private loadStores(pagingData: DfPaging = null, searchQuery: string = null) {
    pagingData = pagingData || new DfPaging(0, 0, 0, 0, false, true, false, 0);
    Utils.setWorking(true);
    this.busy = true;
    this.service
      .getStores({ geolocation: this.geolocationEnabled ? this.geolocation : null, paging: pagingData, searchQuery: searchQuery })
      .then((response: any) => {
        this.stores = pagingData.number > 0 ? this.stores : [];
        this.stores = [...this.stores, ...response.stores];
        if (this.stores.length === 1 && !searchQuery) {
          this.currentStore = this.stores[0];
        }
        this.storesPaging = response.paging;
        Utils.setWorking(false);
        this.busy = false;
        this.storesLoaded = true;
      })
      .catch((error: Error) => {
        Utils.setWorking(false);
        this.busy = false;
        this.storesLoaded = true;
        Utils.defaultApiErrorHandler(error, this);
      });
  }

  private loadStoreContents(typeCode: Array<string>) {
    this.service
      .getStoreContents({
        storeAlias: this.currentStore.alias,
        typeCode: typeCode,
      })
      .then((response: any) => {
        this.$store.dispatch("setStoreContents", response);
      })
      .catch((error: Error) => {
        Utils.defaultApiErrorHandler(error, this);
      });
  }

  private loadStoreOpenings() {
    this.service
      .getStoreOpenings({
        storeAlias: this.currentStore.alias,
        daysInterval: 30,
      })
      .then((response: any) => {
        this.$store.dispatch("setStoreOpenings", response);
      })
      .catch((error: Error) => {
        Utils.defaultApiErrorHandler(error, this);
      });
  }

  private loadStoreClosures() {
    this.service
      .getStoreClosures({
        storeAlias: this.currentStore.alias,
        daysInterval: 30,
      })
      .then((response: any) => {
        this.$store.dispatch("setStoreClosures", response);
      })
      .catch((error: Error) => {
        Utils.defaultApiErrorHandler(error, this);
      });
  }

  private loadPromotions() {
    this.promotionsLoaded = false;
    this.service
      .getPromotions({ storeAlias: this.currentStore.alias })
      .then((promotionsData: Array<DfPromotion>) => {
        this.promotions = promotionsData;
        this.currentPromotion = this.$route.params.promotionAlias ? promotionsData.find((promotion: DfPromotion) => promotion.alias === this.$route.params.promotionAlias) || undefined : null;
        this.promotionsLoaded = true;
      })
      .catch((error: Error) => {
        this.currentPromotion = null;
        this.promotions = [];
        Utils.defaultApiErrorHandler(error, this);
      });
  }

  private loadProducts(pagingData: DfPaging) {
    this.products = pagingData && pagingData.number > 0 ? this.products : [];

    if (this.currentPromotion) {
      let contentAliases: Array<string> = this.selectedTags.map((category: DfCategory) => `contentAliases=${category.identifier}`);
      contentAliases = this.selectedCategory ? [...contentAliases, `contentAliases=${this.selectedCategory.code}`] : contentAliases;

      const query: string = this.productsQueryValue ? `query=${this.productsQueryValue}` : null;

      Utils.setWorking(true);
      this.busy = true;
      this.service
        .getProducts({
          promotionAlias: this.currentPromotion.alias,
          storeAlias: this.currentStore.alias,
          paging: pagingData,
          query: query,
          contentAliases: contentAliases,
          customFilters: this.customFiltersQuery,
        })
        .then((response: any) => {
          this.products = pagingData && pagingData.number > 0 ? this.products : [];
          this.products = [...this.products, ...response.products];
          this.productsPaging = response.paging;
          Utils.setWorking(false);
          this.busy = false;
        })
        .catch((error: Error) => {
          Utils.setWorking(false);
          Utils.defaultApiErrorHandler(error, this);
          this.busy = false;
        });
    }
  }

  private loadCachedProducts(productsUniqueId: Array<string>) {
    if (productsUniqueId.length > 0 && this.currentPromotion) {
      this.service
        .getProductsList({
          promotionAlias: this.currentPromotion.alias,
          storeAlias: this.currentStore.alias,
          productsUniqueId: productsUniqueId,
        })
        .then((products: Array<DfProduct>) => {
          const filteredProducts: Array<DfProduct> = products.filter((product: DfProduct) => !this.cachedProducts.find((cachedProduct: DfProduct) => cachedProduct.uniqueId === product.uniqueId));
          this.cachedProducts = [...this.cachedProducts, ...filteredProducts];
          Utils.setWorking(false);
        })
        .catch((error: Error) => {
          Utils.setWorking(false);
          Utils.defaultApiErrorHandler(error, this);
        });
    }
  }

  private loadCachedProduct(productAlias: string, callback: any = null) {
    if (productAlias && this.currentPromotion) {
      this.service
        .getProduct({
          promotionAlias: this.currentPromotion.alias,
          storeAlias: this.currentStore.alias,
          productAlias: productAlias,
        })
        .then((product: DfProduct) => {
          this.cachedProducts = product && !this.cachedProducts.find((cachedProduct: DfProduct) => cachedProduct.uniqueId === product.uniqueId) ? [...this.cachedProducts, product] : this.cachedProducts;
          Utils.setWorking(false);
          if (callback) {
            callback();
          }
        })
        .catch((error: Error) => {
          Utils.setWorking(false);
          Utils.defaultApiErrorHandler(error, this);
        });
    }
  }

  private loadProductByCode(productCode: string, callback: any = null) {
    if (productCode && this.currentPromotion) {
      this.service
        .getProductByCode({
          promotionAlias: this.currentPromotion.alias,
          storeAlias: this.currentStore.alias,
          productCode: productCode,
        })
        .then((product: DfProduct) => {
          this.cachedProducts = product && !this.cachedProducts.find((cachedProduct: DfProduct) => cachedProduct.uniqueId === product.uniqueId) ? [...this.cachedProducts, product] : this.cachedProducts;
          Utils.setWorking(false);
          if (callback) {
            callback(product);
          }
        })
        .catch((error: Error) => {
          Utils.setWorking(false);
          Utils.defaultApiErrorHandler(error, this);
        });
    }
  }

  private loadPromotionContents(typeCode: Array<string>) {
    if (this.currentPromotion) {
      this.service
        .getPromotionContents({
          promotionAlias: this.currentPromotion.alias,
          storeAlias: this.currentStore.alias,
          typeCode: typeCode,
        })
        .then((response: any) => {
          this.$store.dispatch("setPromotionContents", response);
        })
        .catch((error: Error) => {
          Utils.defaultApiErrorHandler(error, this);
        });
    }
  }

  private loadPromotionGroups() {
    if (this.currentPromotion) {
      this.service
        .getPromotionGroups({
          promotionAlias: this.currentPromotion.alias,
        })
        .then((groups: Array<DfGroup>) => {
          this.$store.dispatch("setPromotionGroups", groups);
        })
        .catch((error: Error) => {
          Utils.defaultApiErrorHandler(error, this);
        });
    }
  }

  private saveShoppingList() {
    if (this.shoppingList.length > 0) {
      Utils.localStorageSetItem("df-shopping-list", JSON.stringify(this.shoppingList));
    } else {
      Utils.localStorageRemoveItem("df-shopping-list");
    }
  }

  private updateHashToLocation() {
    let path = ``;
    if (this.currentStore) path = `${path}/${this.currentStore.alias}`;
    if (this.currentPromotion) path = `${path}/${this.currentPromotion.alias}`;
    history.pushState({}, null, `/${Utils.getContextPath()}${path}`);
  }

  private loadNextProductsPage() {
    if (!this.lastProductsPage && !this.busy) {
      this.productsPaging.number++;
      this.$root.$emit("loadProductsPage", this.productsPaging);
    }
  }

  private initFirebase() {
    Firebase.onMessageReceived(this.handleNotificationMessage, this.handleDataMessage);
    // Wait to app return in foregroud
    document.addEventListener("resume", this.updateStores, false);
  }

  private updateStores() {
    this.loadStore(Utils.localStorageGetItem("df-current-store-alias"));
    this.loadStores();
  }

  private handleNotificationMessage(message: any) {
    let title: string;
    if (message.title) {
      title = message.title;
    } else if (message.notification && message.notification.title) {
      title = message.notification.title;
    } else if (message.aps && message.aps.alert && message.aps.alert.title) {
      title = message.aps.alert.title;
    }

    let body: string;
    if (message.body) {
      body = message.body;
    } else if (message.notification && message.notification.body) {
      body = message.notification.body;
    } else if (message.aps && message.aps.alert && message.aps.alert.body) {
      body = message.aps.alert.body;
    }

    if (title || body) {
      Swal.fire({
        title: title,
        html: body,
        type: "info",
        buttonsStyling: false,
        confirmButtonClass: "modules-button primary round",
      });
    } else if (this.$route.name !== "notifications") {
      this.$router.push("notifications");
    }

    this.loadBrandContents(this.brandContents);
    this.loadStoreContents(this.storeContents);
  }

  private handleDataMessage(message: any) {
    Swal.fire({
      title: "Avviso",
      html: JSON.stringify(message),
      type: "info",
      buttonsStyling: false,
      confirmButtonClass: "modules-button primary round",
    });
  }

  private registerServiceWorker() {
    if ("serviceWorker" in navigator && Utils.getAppType() === "web") {
      // Register a service worker hosted at the root of the
      // site using the default scope.
      navigator.serviceWorker.register(`${Utils.getBasePath(true)}/sw.js`).then(
        (registration) => {
          console.log("Service worker registration succeeded:", registration);
        },
        (error) => {
          console.log("Service worker registration failed:", error);
        }
      );
    } else {
      console.log("Service workers are not supported.");
    }
  }

  private askNotificationPermission() {
    Notification.requestPermission().then((permission) => {
      if (permission === "granted") {
        this.$store.dispatch("setClientNotification", true);
        Utils.localStorageSetItem(Utils.LOCAL_STORAGE_CLIENT_NOTIFICATION, "true");
      }
    });
  }

  private getCurrentPosition() {
    if ("geolocation" in navigator && this.geolocationEnabled) {
      const options: any = { enableHighAccuracy: true, timeout: 5000, maximumAge: 0 };
      navigator.geolocation.getCurrentPosition(this.geolocationSuccess, this.geolocationError, options);
    } else {
      this.loadStores();
    }
  }

  private geolocationSuccess(pos: any) {
    const crd = pos.coords;
    this.geolocation = new DfCoordinates(crd.latitude, crd.longitude);
    this.loadStores();
    console.log("Your current position is:");
    console.log(`Latitude : ${crd.latitude}`);
    console.log(`Longitude: ${crd.longitude}`);
    console.log(`More or less ${crd.accuracy} meters.`);
  }

  private geolocationError(err: any) {
    console.warn(`ERROR(${err.code}): ${err.message}`);
    this.loadStores();
  }

  /*
  NON UTILIZZABILE revoke è infase di  sperimentazione
  private geolocationRevokePermission() {
    navigator.permissions.revoke({name:'geolocation'}).then(() => {
      console.log("Geolocation permission reset");
    });
  }
  */

  private interceptClickEvent(event: any) {
    const target = event.target || {};
    if (target.tagName === "A") {
      let href: string = target.getAttribute("href") || "";
      const hrefSplitted: Array<string> = href.split("/");

      if (hrefSplitted.length === 3 && hrefSplitted[0] === "prodotti") {
        // Gestione link prodotti presenti nel flyer
        //tell the browser not to respond to the link click
        event.preventDefault();
        this.service
          .getProductByLink({ promotionCode: hrefSplitted[1], productCode: hrefSplitted[2], storeAlias: this.currentStore.alias })
          .then((product: DfProduct) => {
            const promotion: DfPromotion = this.promotions.find((promotion: DfPromotion) => promotion.code === hrefSplitted[1]);
            if (promotion) {
              this.$router.push({ name: "product", params: { promotionAlias: promotion.alias, productAlias: product.alias } });
            }
          })
          .catch((error: Error) => {
            Utils.defaultApiErrorHandler(error, this);
          });
      } else if (!href.startsWith("http") && !href.startsWith("blob") && !href.startsWith("mailto") && !href.startsWith("tel") && !href.endsWith("#")) {
        // Gestione link interni (generici)
        // tell the browser not to respond to the link click
        event.preventDefault();
        href = href.replaceAll("../", "");
        href = href.startsWith("/") ? href : `/${href}`;
        href = href.replace(Utils.getBasePath(), "");
        href = href.startsWith("/") ? href : `/${href}`;

        if (href !== this.$route.path) {
          this.$router.push(href);
        }
      }
    }
  }

  private smtPdfViewerEventHandler(event: any) {
    if (event.data.origin === "smt-pdf-viewer") {
      console.log(event);
      switch (event.data.eventType) {
        case "click":
          this.modalProduct = this.products.find((product: DfProduct) => product.code.value === event.data.params.code);
          if (this.modalProduct) {
            this.$root.$emit("modules-modal.open", "df-modal.id.product-details");
            this.setModalAnaliticsTraker();
          } else {
            this.loadProductByCode(event.data.params.code, (product: DfProduct) => {
              this.modalProduct = product;
              if (this.modalProduct) {
                this.$root.$emit("modules-modal.open", "df-modal.id.product-details");
                this.setModalAnaliticsTraker();
              }
            });
          }
          break;
        case "pagechanging":
          Utils.setAnaliticsTraker({
            event: "view_page",
            page: event.data.params.pageNumber,
          });
          break;
        case "scalechanging":
          Utils.setAnaliticsTraker({
            event: event.data.params.scale < event.data.params.previousScale ? "click_zoom_out" : "click_zoom_in",
          });
          break;
        case "fullscreen":
          if (event.data.params.isFullscreen) {
            Utils.setAnaliticsTraker({ event: "click_fullscreen" });
          }
          break;
        case "download":
          Utils.setAnaliticsTraker({
            event: "click_download",
            origin: "pdf_viewer",
          });
          break;
        case "print":
          Utils.setAnaliticsTraker({
            event: "print",
            origin: "pdf_viewer",
          });
          break;
        case "find":
          clearTimeout(this.searchPdfViewerInterval);
          this.searchPdfViewerInterval = setTimeout(() => {
            Utils.setAnaliticsTraker({
              event: "search",
              origin: "pdf_viewer",
              query: event.data.params.query,
            });
          }, 2000);
          break;
        case "sidebarviewchanged":
          Utils.setAnaliticsTraker({
            event: "toggle_left_menu",
            origin: "pdf_viewer",
            open: event.data.params.view,
          });
          break;
      }
    }
  }

  private closeProductDetailModal() {
    if (this.modalProduct) {
      Utils.setAnaliticsTraker({
        event: "close_product_modal",
        store: this.currentStore.name,
        promo_name: this.currentPromotion.description,
        product: this.modalProduct.description,
      });
      this.modalProduct = null;
    }
  }

  private setModalAnaliticsTraker() {
    Utils.setAnaliticsTraker({
      event: "click_product",
      store: this.currentStore.name,
      promo_name: this.currentPromotion.description,
      product: this.modalProduct.description,
      origin: "pdf_viewer",
      context: "list",
    });
  }
}
